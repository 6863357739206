<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";




 //this.vet2= [],
    ///  console.log(this.listGrupos[0].descricaogrupo),
      ///this.vet2.push(this.listGrupos[0].descricaogrupo),
      ///console.log(this.vet2)
export default {

  components: { Multiselect  },
   props: { 
      currentEmpresa: {},
      listGrupos: { type: Array, required: true },
      listGrupos2: { type: Array, required: true },
    },
    
  data() {
    return {
      loader: {get: false},
      titleBody: 'Incluir Grupos',
      parentSelected: null,
      grupo: {
        descricaogrupo: "",
        grupo_tag: 0,
        parent_id: 0,
        opencart: 0,
        oc_name: null,
        status: 1,
        oc_top: null,
        empresa_id: 1,
        grupo_touch:-1
      },
    }
  },
  validations() {
    
    return {
      grupo: {
        descricaogrupo: { required },
        grupo_tag: { required },
        oc_name: { required },
        grupo_touch: {required}
      }
    }
  },
  
  mounted() {
      this.setNewTitle();
  },
  methods: {
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit('doPost', this.grupo);
    },
    
  },
    
}


</script>

<template>
<div class="card">
  <div class="card-body">
    <form id="form-centro-custo" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-2">
              <div class="form-group">
                <label for="centro_custo_id" class="col-form-label">Código</label>
                <div class="col">
                  <input disabled name="id" class="form-control text-right" type="text" placeholder="Gerado pelo sistema" id="centro_custo_id">
                </div>
              </div>
            </div>
            
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_grupos_receitavenda" class="col-form-label">Grupo Pai</label>
                <div class="col">
                  <!--<multiselect  :options="listGrupos" placeholder="Selecione"
                    label="name">
                    <template slot="singleLabel" slot-scope="{ option }">{{option.id}}</template>
                  </multiselect> -->
                    <multiselect v-model="parentSelected"  :options="listGrupos2" placeholder="Selecione"
                     track-by="id" deselect-label="Pressione ENTER para remover" label="descricaogrupo"
                     @input="selectTipoGruopo">
                    <template slot="singleLabel" slot-scope="{ option }">{{option.descricaogrupo}}</template>
                  </multiselect> 
                  
                    
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-1">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="grupo-status"
                   v-model="grupo.status"
                   name="touch"
                   value="1"
                   unchecked-value="0"
                   class="altt"
                   
                  >
                      Ativo
                  </b-form-checkbox>
                  
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-1">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-touc-touch"
                   v-model="grupo.grupo_touch"
                   name="outro"
                   value="1"
                   unchecked-value="0"
                   class="altt"
                  >
                      touch
                  </b-form-checkbox>
                  
                </div>
              </div>
            </div>
            
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="centro_custo_identificacao" class="col-form-label">Descrição</label>
                  <div class="col">
                    <input v-model="grupo.descricaogrupo" class="form-control" type="text" placeholder="Descrição grupo" id="centro_custo_identificacao">
                  </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-10">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-pagamento-touch"
                   name="outro2"
                   value="1"
                   v-model="grupo.opencart"
                   unchecked-value="0"
                  >
                      Integar OpenCar
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="centro_custo_identificacao" class="col-form-label">Departamento</label>
                  <div class="col">
                    <input v-model="grupo.oc_name"  class="form-control" type="text" placeholder="Descrição grupo" id="centro_custo_identificacao">
                  </div>
              </div>
            </div>

            
            <div class="col-sm-12 col-md-3" id="mod">
              <div class="form-group">
                <div class="col">
                    <p class="altt3">Exibir no topo ?</p>
                  
                </div>
              </div>
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-touc-touch2"
                   v-model="grupo.oc_top"
                   name="outro22"
                   value="1"
                   unchecked-value="0"
                   class="altt2"
                  >
                      sim
                  </b-form-checkbox>
                  
                </div>
                <div class="col">
                  <b-form-checkbox
                   id="tipo-touc-touch1"
                   v-model="grupo.oc_top"
                   name="outro33"
                   value="0"
                   unchecked-value="0"
                   class="altt2"
                  >
                      não
                  </b-form-checkbox>
                  
                </div>
              </div>
              
            </div>
            
          </div>
          
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPost">Gravar</button>
    </div>
  </div>
</div>
</template>

<style>

#mod{
  
}
.altt {
  margin-top:50px;
 }
 .altt2 { 
  margin-left:20px;
 }
.altt3 {
  margin-top:50px;
 }



</style>