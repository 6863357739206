<script>
export default {
  props: {
    grupos: { type: Object }
  },
}
</script>

<template>
<div id="grupos">
  <p>Descricao: {{ grupos.descricaogrupo }}</p>
  <p>grupos: {{ grupos.oc_name}} </p>
  <p>ID_empresa: {{ grupos.empresa_id}} </p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ grupos.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ grupos.updated_at }}</b-badge></p>
</div>
</template>
