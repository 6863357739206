<script>
import appConfig from "@/app.config";
import { authEasyMethods, notificationMethods } from '@/state/helpers';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header';
import List from "./list.vue";
import Insert from "./insert.vue";
import Edit from "./edit.vue";
//import { http } from '../../../../helpers/easyindustriaapi/config';

import Controller_grupos from './Controller_grupos';


export default {
  page: {
    title: 'Grupos',
    meta: [{ name: 'description', content: appConfig.description }]
  },
  
  components: { Layout, PageHeader,Insert,List,Edit},
  data() {
    return {
      currentGrupos:{},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Parâmetros",
          href: "/",
          active: true,
        },
        {
          text: "Grupos",
          href: "/parametros/grupos",
          active: true,
        }
      ],
     

      //grupo: undefined,
      //T_grupos: [],
      grupos: undefined,
      T_grupos:[],
      T_grupos2:[],
      titleBody: 'Grupos',
      editando: false,
      inserindo: false,
    }
  },

  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user')); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));// para saber a emrpessa atual;
    // storage esta no navegador em aplucation;descricaogrupo
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return (!this.inserindo && !this.editando);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    // async getData() {
    //   this.onLoader();

    //   try {
    //     let response = await http.get('/grupo?empresa_id='+this.currentEmpresa.id);
       
    //     if (response.status === 200) {
          
    //       this.T_grupos = response.data;
    //       setTimeout(() => {
    //         this.offLoader();
    //       }, 2000);// tempo para carregar a tela;
    //     } else {
    //       this.T_grupos = []; // zera o array;
    //       this.makeToast('danger', 'Erro ao carregar lista');
    //       this.offLoader();
    //     }
    //   } catch (error) {
    //     this.offLoader();

    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast('danger', 'Destino URL não encontrado!');
    //       } else {
    //         this.makeToast('danger', error.response);
    //       }
    //     }
    //   }
    // },
    async getData() {
      this.T_grupos = await Controller_grupos.getData(
        this.currentEmpresa,
        this.onLoader,
        this.offLoader,
        this.makeToast
      );
    },
    setGrupos(grupos) {
      this.currentGrupos = grupos;
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(oldGrupos) {
      this.setGrupos(oldGrupos);
      this.editando = !this.editando;
    },
    
    // async doPost(grupos) {
    //   // id
    //   // DescricaoUn
    //   // un_codigo
    //   // empresa_id

    //   grupos.empresa_id = this.currentEmpresa.id;
    //   grupos.user_id = this.currentUser.id;
      

    //   this.onLoader();

    //   try {
    //     let response = await http.post('/grupo?empresa_id='+this.currentEmpresa.id, grupos);
    //     if (response) {
    //       this.offLoader();
    //       if (response.status === 200) {
    //         this.getData();
    //         this.inserindo = false;
    //         this.makeToast('success', 'Registro incluído');
    //       }
    //     }
    //   } catch (error) {
    //     this.offLoader();
    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast('danger', 'Destino URL não encontrado!');
    //       } else {
    //         this.makeToast('danger', error);
    //       }
    //     }
    //   }

      
    // },

    async doPost(grupo){  // codigo rafatorado; //  bem mais enxuto e legivel;
      const { getData, onLoader, offLoader, makeToast} = this;
      await Controller_grupos.doPost(grupo, this.currentEmpresa, this.currentUser, getData, onLoader, offLoader, makeToast);
      this.inserindo = false
    },

    // async doPut(grupos) {
    //   this.onLoader();

    //   try {
    //     let response = await http.put('/grupo/'+grupos.id+'?empresa_id='+this.currentEmpresa.id, grupos);
    //     if (response) {
    //       this.offLoader();
    //       if (response.status === 200) {
    //         this.makeToast('success', 'Registro alterado');
    //         this.getData();
    //         this.editando = false;
    //       }
    //     }
    //   } catch (error) {
    //     this.offLoader();
    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast('danger', 'Destino URL não encontrado!');
    //       } else {
    //         this.makeToast('danger', error);
    //       }
    //     }
    //   }
    // },
    async doPut(grupos) {
      await Controller_grupos.doPut1(grupos, this.currentEmpresa, this.currentUser, this.getData, this.onLoader, this.offLoader, this.makeToast);
      this.editando = false;
      this.getData();
    },
    // async doDelete(grupos) {
    //   this.onLoader();

    //   try {
    //     let response = await http.delete('/grupo/'+grupos.id+'?empresa_id='+this.currentEmpresa.id, grupos);
    //     if (response) {
          
    //       if (response.status === 200) {
    //         this.getData();
    //         this.offLoader();
    //         this.makeToast('warning', 'Registro excluído');
    //       }
    //     }
    //   } catch (error) {
    //     this.offLoader();
    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast('danger', 'Destino URL não encontrado!');
    //       } else {
    //         this.makeToast('danger', error);
    //       }
    //     }
    //   }
    // },

    async doDelete(grupo) {
      await Controller_grupos.doDelete(grupo, this.currentEmpresa, this.currentUser, this.getData, this.onLoader, this.offLoader, this.makeToast);
      this.getData();
    },
    
    
    back() {
      this.inserindo = false;
      this.editando  = false;
      this.titleBody = 'Grupos';
    },
  },
}
</script>

<template>
<Layout>
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Grupo</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div v-if="!isHide" class="card-body">
          

           <Edit v-if="editando"
           :empresa="currentEmpresa"
           :oldGrupos="currentGrupos"
           :listGrupos2="T_grupos"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />

          <Insert v-if="inserindo"
          :listGrupos="T_grupos"
          :listGrupos2="T_grupos"
          :currentEmpresa="currentEmpresa"
           @newTitle="changeHomeTitle"
           @doPost="doPost"
          />

         
        </div>

        <List
         v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listGrupos="T_grupos"
         :hide="isHide"
         @edit="edit"
         @newTitle="changeHomeTitle"
         @setGrupos="setGrupos"
         @doDelete="doDelete"
        />
        
      </div>
    </div>
  </div>
</Layout>
</template>