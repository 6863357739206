<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";




export default {
  props: { 
      oldGrupos: { type: Object, required: true },
      listGrupos2: { type: Array, required: true },
     /// parentSelected: { type: Object, required: true }
    },
  components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Grupo',
      grupo: {},
      teste:{
        descricaogrupo: null
      } // crieo isso ,pq se usar grupo.descrção 
      // sobreescre a descrição e da um erro , quando vai  visualizar;
     
      
    }
    
    
  },
  validations: {
    grupo:{
        descricaogrupo: {required},
        grupo_tag: {required},
        parent_id: {required},
        opencart: {required},
        oc_name: {required},
        status:{required},
        oc_top: {required},
        empresa_id:{required},
        grupo_touch:{required}

      }
  },
  mounted() {
    this.setNewTitle();
    this.grupo = this.oldGrupos;
    this.teste= this.grupo
    
  
    
    
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log('Gravou');
      this.$emit('doPut', this.grupo);
    },
   
  },
    
}
</script>

<template>

<div class="card">
  <div class="card-body">
    <form id="form-centro-custo" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-2">
              <div class="form-group">
                <label for="grupo_id" class="col-form-label">Código</label>
                <div class="col">
                  <input v-model="teste.id"  class="form-control text-right" type="text" placeholder="Gerado pelo sistema" id="centro_custo_id">
                </div>
              </div>
            </div>
            
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_grupos_receitavenda" class="col-form-label">Grupo Pai</label>
                <div class="col">
                  <!--<multiselect  :options="listGrupos" placeholder="Selecione"
                    label="name">
                    <template slot="singleLabel" slot-scope="{ option }">{{option.id}}</template>
                  </multiselect> -->
                    <multiselect v-model="teste" :options="listGrupos2" placeholder="Selecione"
                       deselect-label="Pressione ENTER para remover" label="descricaogrupo" track-by="id"
                     >
                    <template slot="singleLabel" slot-scope="{ option }">{{option.descricaogrupo}}</template>
                  </multiselect> 
                      
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-1">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="grupo-status"
                   v-model="grupo.status"
                   name="touch"
                   value="1"
                   unchecked-value="0"
                   class="altt"
                   
                  >
                      Ativo
                  </b-form-checkbox>
                  
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-1">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-touc-touch"
                   v-model="grupo.grupo_touch"
                   name="outro"
                   value="1"
                   unchecked-value="0"
                   class="altt"
                  >
                      touch
                  </b-form-checkbox>
                  
                </div>
              </div>
            </div>
            
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="centro_custo_identificacao" class="col-form-label">Descrição</label>
                  <div class="col">
                    <input v-model="grupo.descricaogrupo" class="form-control" type="text" placeholder="Descrição grupo" id="centro_custo_identificacao">
                  </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-10">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-pagamento-touch"
                   name="outro2"
                   value="1"
                   v-model="grupo.opencart"
                   unchecked-value="0"
                  >
                      Integar OpenCar
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label for="centro_custo_identificacao" class="col-form-label">Departamento</label>
                  <div class="col">
                    <input v-model="grupo.oc_name"  class="form-control" type="text" placeholder="Descrição grupo" id="centro_custo_identificacao">
                  </div>
              </div>
            </div>

            
            <div class="col-sm-12 col-md-3" id="mod">
              <div class="form-group">
                <div class="col">
                    <p class="altt3">Exibir no topo ?</p>
                  
                </div>
              </div>
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-touc-touch2"
                   v-model="grupo.oc_top"
                   name="outro22"
                   value="1"
                   unchecked-value="0"
                   class="altt2"
                  >
                      sim
                  </b-form-checkbox>
                  
                </div>
                <div class="col">
                  <b-form-checkbox
                   id="tipo-touc-touch1"
                   v-model="grupo.oc_top"
                   name="outro33"
                   value="0"
                   unchecked-value="0"
                   class="altt2"
                  >
                      não
                  </b-form-checkbox>
                  
                </div>
              </div>
              
            </div>
            
          </div>
          
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPut">Alterar</button>
    </div>
  </div>
</div>
</template>